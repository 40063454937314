import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  iconSection: {
    '& svg': {
      fill: theme.palette.text.primary
    }
  },
  titleSection: {
    marginLeft: theme.spacing(2),
    width: '100%',
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`
  },
  title: {
    width: '100%',
    // maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
    fontSize: '1.5rem',
    wordWrap: 'break-word',
    // whiteSpace: 'nowrap',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden'
  },
  address: {
    width: '100%',
    fontSize: theme.custom.sizes.small
  },
  marker: {
    display: 'inline-block'
  },
  distance: {
    display: 'inline-block',
    marginLeft: 30
  }
}), { name: 'organization-card' });
