import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: 300,
    padding: 24,
    position: 'relative'
  },

  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30,
    lineHeight: 1
  },

  icon: {
    position: 'absolute',
    top: 24,
    right: 24
  },

  paragraph: {
    fontSize: 16
  }

}), { name: 'metrics-card' });
