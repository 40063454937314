import classNames from 'classnames';
import React, { useState } from 'react';
import type { FC } from 'react';
import type { RouteComponentProps } from 'react-router';

import styles from './styles';
import DetailsView, { SelectedTransaction } from './views/Details';
import ListView from './views/List';
import { ExpenseKeyREGIOS } from '@/interfaces';

type Props = RouteComponentProps<{category: string}>;

const TransactionsView: FC<Props> = ({ match, history, location }) => {
  const { category } = match.params;
  const classes = styles();

  const [listScrollPosition, setListScrollPosition] = useState(0);
  const selectedTransaction = history.location.state as SelectedTransaction|undefined;
  setTimeout(() => {
    const mainContent = document.getElementById('main-content');
    if (mainContent && listScrollPosition) {
      mainContent.scrollTop = listScrollPosition;
    }
  });
  return (
    <div className={ classes.root }>
      <ListView
        category={ (category as ExpenseKeyREGIOS) }
        className={ classNames({
          [classes.hidden]: !!selectedTransaction
        }) }
        hidden={ !!selectedTransaction }
        onSelect={ (transaction, organization): void => {
          const mainContent = document.getElementById('main-content');
          if (mainContent) {
            setListScrollPosition(mainContent.scrollTop);
            mainContent.scrollTop = 0;
          }
          history.push(location.pathname, { transaction, organization });
        } }
      />
      {
        selectedTransaction && (
          <DetailsView
            transaction={ selectedTransaction }
          />
        )
      }
    </div>
  );
};

export default TransactionsView;
