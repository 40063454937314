import {
  Paper, Typography
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'use-async-effect';
import ColoredPaper from '@/components/ColoredPaper';
import { showNotification } from '@/components/Notification';
import { AlternativeOrganizationREGIOS, Organization, TransactionREGIOS } from '@/interfaces';
import APIService from '@/services/APIService';
import { getText } from '@/utils/data';
import OrganizationCard from '../../components/OrganizationCard';

const WrapWithPaper: FC = ({ children }) => (
  <Paper>
    <Box
      mt={ 2 }
      p={ 2 }
    >
      { children }
    </Box>
  </Paper>
);

export interface SelectedTransaction {
  organization?: Organization;
  transaction: TransactionREGIOS;
}

interface Props {
  transaction: SelectedTransaction;
}

const TransactionsDetailsView = ({ transaction }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { organization, transaction: { org_distance, org_id, regiox_rating } } = transaction;
  const name = organization?.name;
  const orgName = useMemo(() => getText(name), [name]);
  const distance = org_distance;
  const title = t('expenses.regios.title_regional_options');
  const rating = regiox_rating;
  const [alternatives, setAlternatives] = useState<ReadonlyArray<AlternativeOrganizationREGIOS>>();
  useAsyncEffect(async () => {
    if (org_id) {
      try {
        const { data } = await APIService.regios.getRegionalAlternatives(org_id);
        setAlternatives(data.alternatives);
      } catch ({ message }) {
        if (message.includes('404')) {
          setAlternatives([]);
        } else {
          showNotification({ content: t('common.internal_error'), type: 'error' });
        }
      }
    } else {
      setAlternatives([]);
    }
  }, [org_id]);

  return (
    <Box px={ 2 }>
      <Box pl={ 4 }>
        <OrganizationCard
          address={ organization?.address?.address }
          distance={ distance }
          logoPath={ organization?.logo_sqrd }
          name={ orgName }
          rating={ rating }
        />
      </Box>
      { Array.isArray(alternatives)
      && (
        <Box mt={ 2 }>
          <ColoredPaper>
            <Box p={ 2 }>
              <Typography variant="h5">{ title }</Typography>
              {
                alternatives.length
                  ? alternatives.map((alternative, idx) => (
                    <WrapWithPaper key={ idx }>
                      <OrganizationCard
                        key={ idx }
                        address={ alternative?.address?.address }
                        distance={ alternative.org_distance }
                        logoPath={ alternative.logo_sqrd }
                        name={ alternative.name }
                        rating={ alternative.regiox_rating }
                      />
                    </WrapWithPaper>
                  ))
                  : (
                    <WrapWithPaper>
                      <Typography>{ t('expenses.regios.no_regional_options') }</Typography>
                    </WrapWithPaper>
                  )
              }
            </Box>
          </ColoredPaper>
        </Box>
      ) }
    </Box>
  );
};

export default TransactionsDetailsView;
