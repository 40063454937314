import { parse, isSameDay, sub } from 'date-fns';
import { SingleTransaction } from '../../components/Item';

interface SingleGroup {
  date: Date;
  transactions: SingleTransaction[];
  firstOlder30days?: boolean;
}

const groupTransactionsByDay = (
  transactions: ReadonlyArray<SingleTransaction>
): ReadonlyArray<SingleGroup> => {
  let firstOlder30Found = false;
  let firstOlder30days = false;
  const before30days = sub(new Date(Date.now()), { days: 30 });
  return transactions.reduce<SingleGroup[]>((previous, current) => {
    const transactionDate = parse(current.transaction.valueDate, 'yyyy-MM-dd', new Date());
    if (!firstOlder30Found) {
      if (transactionDate < before30days) {
        firstOlder30days = true;
        firstOlder30Found = true;
      }
    } else {
      firstOlder30days = false;
    }
    let el = previous.find((singleEl) => isSameDay(singleEl.date, transactionDate));
    if (!el) {
      el = {
        date: transactionDate,
        transactions: [],
        firstOlder30days
      };
      previous.push(el);
    }

    el.transactions.push(current);

    return previous;
  }, []);
};
export default groupTransactionsByDay;
