export default {
  50: 'bi_annually',
  100: 'annually',
  200: 'twice_per_year',
  400: 'quarterly',
  1200: 'monthly',
  2600: 'bi_weekly',
  5200: 'weekly',
  10400: 'twice_per_week',
  36400: 'daily'
};
