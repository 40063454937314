/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  CircularProgress,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { format, isToday, isYesterday } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAsyncEffect } from 'use-async-effect';

import groupTransactionsByDay from './helpers';
import styles from './styles';
import { showNotification } from '@/components/Notification';
import useCategories from '@/hooks/useCategoryLabel';
import { Pagination, Organization, TransactionREGIOS, ExpenseKeyREGIOS } from '@/interfaces';
import APIService from '@/services/APIService';
import { PropsWithClassName } from '@/types';
import { getCurrentDateFormat } from '@/utils/format';
import { SingleTransaction } from '../../components/Item';
import SameDayTransactions from '../../components/SameDayTransactions';

interface Props {
  hidden?: boolean;
  category: ExpenseKeyREGIOS;
  onSelect?: (transaction: TransactionREGIOS, org?: Organization) => void | Promise<void>;
}

const TransactionsListView = ({ className, hidden, category, onSelect }: PropsWithClassName<Props>): JSX.Element => {
  const classes = styles();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<Pagination>();
  const [transactions, setTransactions] = useState<SingleTransaction[]>([]);

  const organizationsHelper = useRef<{ [key: string]: Organization }>({});

  const currentDateFormat = useMemo(() => getCurrentDateFormat(), []);

  const groupedTransactions = useMemo(() => groupTransactionsByDay(transactions), [transactions]);

  const getDateString = useCallback((date: Date): string => {
    if (isToday(date)) {
      return t('time.today');
    }
    if (isYesterday(date)) {
      return t('time.yesterday');
    }
    return format(date, currentDateFormat);
  }, [t, currentDateFormat]);

  const groupedTransactionsWithLabel = useMemo(() => groupedTransactions.map(({ date, ...trans }) => ({ ...trans, label: getDateString(date) })), [groupedTransactions, getDateString]);


  useAsyncEffect(async () => {
    try {
      const resultData: SingleTransaction[] = [];
      const { data } = await APIService.transaction.getAllByCategoryREGIOS(category, currentPage, 15);

      const missingOrgs: string[] = Array.from(new Set(data.transactions.map((singleTransaction) => singleTransaction.org_id))).filter((id) => id && !organizationsHelper.current[id]) as string[];
      if (missingOrgs.length) {
        const { data: missingOrgsData } = await APIService.description.getOrganizations(missingOrgs);
        missingOrgsData.forEach((singleOrganization) => {
          organizationsHelper.current[singleOrganization.id] = singleOrganization;
        });
      }

      for (const transaction of data.transactions) {
        resultData.push({
          organization: transaction.org_id ? organizationsHelper.current[transaction.org_id] : undefined,
          transaction
        });
      }
      setTransactions((currentTransactions) => [...currentTransactions || [], ...resultData]);
      setPagination(data.paging);
    } catch {
      showNotification({ content: t('common.internal_error'), type: 'error' });
    }
  }, [currentPage]);
  const { getLabel } = useCategories();
  return (
    <div className={ classNames([classes.root, className]) }>
      <InfiniteScroll
        dataLength={ transactions ? transactions.length : 0 }
        hasMore={ !hidden && (pagination?.pageCount || 1) > currentPage }
        loader={ <div className={ classes.loader }><CircularProgress /></div> }
        next={ (): void => { setCurrentPage(currentPage + 1); } }
        scrollableTarget="main-content"
        scrollThreshold="100px"
      >
        <Typography
          className={ classes.rootTitle }
          variant="h4"
        >
          { t('expenses.regios.title_per_category', { category: getLabel(category) }) }
        </Typography>

        {
          pagination && pagination.totalCount
            ? groupedTransactionsWithLabel.map((group, idx) => (
              <SameDayTransactions
                key={ idx }
                onSelectItem={ onSelect }
                { ...group }
              />
            ))
            : null
        }
        {
          pagination && !pagination.totalCount
            ? <Typography className={ classes.emptyListMessage }>{ t('expenses.regios.no_transactions_for_category') }</Typography>
            : null
        }
      </InfiniteScroll>
    </div>
  );
};

export default TransactionsListView;
