import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useTranslation } from 'react-i18next';

const Header: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Box component="header">
      <Typography
        variant="h4"
      >
        { t('overview.regios.title') }
      </Typography>
      <Typography
        variant="h6"
      >
        { t('overview.regios.sub-title') }
      </Typography>
    </Box>
  );
};

export default Header;
