/* eslint-disable no-loop-func */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PaginationEl from '@material-ui/lab/Pagination';
import classNames from 'classnames';
import { addMonths, subMonths } from 'date-fns';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Row, { SingleSubscription } from './Row';
import styles from './styles';
import CategorySunburstChart from '@/components/CategorySunburstChart';
import { loading, closeLoading } from '@/components/CustomLoader';
import { IDateRangePickerConfig } from '@/components/DateRangePicker';
import { showNotification } from '@/components/Notification';
import PageHeader from '@/components/PageHeader';
import CustomTableBody from '@/components/Table/Body';
import { Pagination, Organization, Subscription } from '@/interfaces';
import { AppState } from '@/reducers';
import APIService from '@/services/APIService';
import { useMobile } from '@/utils/hooks';

const MAX_CHART_WIDTH = 250;

const CLASS_FILTER_ARRAY = ['trr', 'brb', 'brc', 'brx', 'brd', 'prf'];

interface Props {
  className?: string;
  onSelect?: (subscription: Subscription) => void | Promise<void>;
}

const ListView = ({ className = '', onSelect = (): void => {} }: Props): JSX.Element => {
  const classes = styles();
  const isMobile = useMobile();
  const { t } = useTranslation();

  const cashbuzzQClasses = useSelector((state: AppState) => state.cashbuzz.qclasses);

  const [activePage, setActivePage] = useState(1);
  const [chartWidth, setChartWitdth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState<SingleSubscription[]>([]);
  const [subscriptionListPagination, setSubscriptionListPagination] = useState<Pagination>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 3));

  const isInitRef = useRef(true);
  const loadingIdRef = useRef<null|number>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const organizationsHelper = useRef<{[key: string]: Organization}>({});

  const dateRangePickerConfig: IDateRangePickerConfig = {
    start: startDate,
    end: endDate,
    onStartChange: (d) => setStartDate(d),
    onEndChange: (d) => setEndDate(d)
  };

  const fetchSubscriptions = useCallback(async (page: number, offLoading = false): Promise<void> => {
    setIsLoading(true);
    const resultData: SingleSubscription[] = [];
    const { data } = await APIService.subscriptions.getAllByPeriod(startDate, endDate, false, page);

    const missingOrgs: string[] = Array.from(new Set(data.subscriptions.map((singleSubscription) => singleSubscription.org_id))).filter((id) => id && !organizationsHelper.current[id]) as string[];
    if (missingOrgs.length) {
      const { data: missingOrgsData } = await APIService.description.getOrganizations(missingOrgs);

      missingOrgsData.forEach((singleOrganization) => {
        organizationsHelper.current[singleOrganization.id] = singleOrganization;
      });
    }

    for (const subscription of data.subscriptions) {
      if (CLASS_FILTER_ARRAY.includes(subscription.class)) {
        continue;
      }

      const industry = cashbuzzQClasses.find((qclass) => qclass.label === subscription.qorg);

      resultData.push({
        industry,
        organization: subscription.org_id ? organizationsHelper.current[subscription.org_id] : undefined,
        subscription
      });
    }
    setSubscriptionList(resultData);
    setSubscriptionListPagination(data.paging);

    if (offLoading) {
      setIsLoading(false);
    }
  }, [cashbuzzQClasses, startDate, endDate]);

  const fetchChartData = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const s = await APIService.subscriptions.getChartData(false, startDate, endDate);
    setSubscriptions(s);
  }, [startDate, endDate]);

  useEffect(() => {
    const resizeHandler = (): void => {
      if (elementRef.current) {
        const multiplier = window.innerWidth > 780 ? 2 : 1;
        const newChartWidth = elementRef.current.offsetWidth / multiplier - 40;
        setChartWitdth(newChartWidth > MAX_CHART_WIDTH ? MAX_CHART_WIDTH : newChartWidth);
      }
    };

    const fetchAll = async (): Promise<void> => {
      setIsLoading(true);

      try {
        await fetchSubscriptions(1);
        await fetchChartData();
      } catch {
        showNotification({ content: t('common.internal_error'), type: 'error' });
      } finally {
        setIsLoading(false);
        isInitRef.current = false;
      }
    };

    resizeHandler();
    fetchAll();
    window.addEventListener('resize', resizeHandler);

    return (): void => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [fetchChartData, fetchSubscriptions, t]);

  useEffect(() => {
    if (isLoading) {
      loadingIdRef.current = loading({ elementSelector: 'main .dashboard-view-element-root' });
    } else {
      closeLoading(loadingIdRef.current);
      loadingIdRef.current = null;
    }
  }, [isLoading]);

  useEffect(() => (): void => {
    if (loadingIdRef.current) {
      closeLoading(loadingIdRef.current);
    }
  }, []);

  useEffect(() => {
    if (!isInitRef.current) {
      fetchSubscriptions(activePage, true);
    }
  }, [activePage, fetchSubscriptions]);

  return (
    <div
      ref={ elementRef }
      className={ classNames({
        [classes.root]: true,
        [className]: true
      }) }
    >
      <PageHeader
        dateRangePicker={ dateRangePickerConfig }
        title={ t('expenses.general.title') }
      />
      <Grid
        className={ `${classes.root}-charts` }
        container
        spacing={ 2 }
      >
        <Grid
          className={ `${classes.root}-charts-single` }
          item
          xs={ 6 }
        >
          <Typography
            className="section-title"
            variant="h4"
          >
            { t('expenses.general.charts.expenses_by_source') }
          </Typography>
          <CategorySunburstChart
            description={ t('expenses.general.charts.hint_description') }
            qClass="qorg"
            startNode={ 3 }
            subscriptions={ subscriptions }
            title={ t('common.details') }
            width={ chartWidth }
          />
        </Grid>
        <Grid
          className={ `${classes.root}-charts-single` }
          item
          xs={ 6 }
        >
          <Typography
            className="section-title"
            variant="h4"
          >
            { t('expenses.general.charts.expenses_by_expenses_type') }
          </Typography>
          <CategorySunburstChart
            description={ t('expenses.general.charts.hint_description') }
            startNode={ 2 }
            subscriptions={ subscriptions }
            title={ t('common.details') }
            width={ chartWidth }
          />
        </Grid>
      </Grid>
      <div className={ `${classes.root}-table` }>
        <TableContainer
          component={ Paper }
          style={ { overflowY: 'hidden' } }
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={ { width: 63 } } />
                <TableCell>
                  { t('common.logo') }
                </TableCell>
                <TableCell>
                  { t('subscription.name') }
                </TableCell>
                {
                  !isMobile && (
                    <TableCell>
                      { t('subscription.industry') }
                    </TableCell>
                  )
                }
                <TableCell align="right">
                  { t('subscription.total_amount') }
                </TableCell>
                {
                  !isMobile && (
                    <TableCell>
                      { t('subscription.period') }
                    </TableCell>
                  )
                }
                {
                  !isMobile && (
                    <TableCell align="right">
                      { t('subscription.number_of_transactions') }
                    </TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <CustomTableBody
              customEmptyText={ t('expenses.general.table.no_data') }
              empty={ !subscriptionList.length }
              hasPagination={ !!subscriptionListPagination?.pageCount }
              loading={ isLoading }
            >
              {
                subscriptionList.map((subscription) => (
                  <Row
                    key={ subscription.subscription.id }
                    industry={ subscription.industry }
                    isMobile={ isMobile }
                    onSelect={ onSelect }
                    organization={ subscription.organization }
                    subscription={ subscription.subscription }
                  />
                ))
              }
            </CustomTableBody>
          </Table>
          {
            subscriptionListPagination && subscriptionListPagination.pageCount > 1
            && (
              <PaginationEl
                color="primary"
                count={ subscriptionListPagination?.pageCount || 0 }
                onChange={ (event, page): void => { setActivePage(page); } }
              />
            )
          }
        </TableContainer>
      </div>
    </div>
  );
};

export default ListView;
