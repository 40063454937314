import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Formatters = {
  currency: Intl.NumberFormat;
  float: Intl.NumberFormat;
}
type Result = {
  formatCurrency: (amount: number) => string;
  formatFloat: (amount: number) => string;
}

export default function useIntlNumberFormatter(): Result {
  const { i18n } = useTranslation();
  const setLanguage = useCallback(
    (lang: string) => setFormatters({
      currency: new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR'
      }),
      float: new Intl.NumberFormat(lang)
    }),
    []
  );
  const [formatters, setFormatters] = useState<Formatters>({
    currency: new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: 'EUR'
    }),
    float: new Intl.NumberFormat(i18n.language)
  });
  const formatCurrency = useCallback(
    (amount) => formatters?.currency.format(amount),
    [formatters]
  );
  const formatFloat = useCallback(
    (amount) => formatters?.float.format(amount),
    [formatters]
  );

  i18n.on('languageChanged', (lang) => {
    setLanguage(lang);
  });

  return {
    formatCurrency,
    formatFloat
  };
}
