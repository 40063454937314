import distinctColors from 'distinct-colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SunburstWithLegend from '@/components/SunburstWithLegend';
import { Subscription } from '@/interfaces';
import { AppState } from '@/reducers';
import { getCurrentLanguage } from '@/utils/languages';


interface Props {
  description: string;
  title: string;
  width?: number;
  showStartNode?: boolean;
  skipFullRings?: boolean;
  startNode?: number;
  qClass?: string;
  subscriptions: Subscription[];
}

const CategorySunburstChart = (p: Props): JSX.Element => {
  const { t } = useTranslation();
  const cashbuzzQClasses = useSelector((state: AppState) => state.cashbuzz.qclasses);
  const [chartData, setChartData] = useState<any>({ children: [], maxDeep: 0 });
  const chartWidth = p.width ? p.width : 400;

  useEffect(() => {
    let helpIndex = 0;
    const currentLanguage = getCurrentLanguage();
    const qClass = p.qClass ? p.qClass : 'qaction';
    const startNode = p.startNode ? p.startNode : 0;
    const data: { children: any[]; maxDeep: number } = { children: [], maxDeep: 0 };

    p.subscriptions.forEach((subscription) => {
      const cashbuzzQClass = cashbuzzQClasses?.find((iterClass) => {
        let result = false;
        switch (qClass) {
          case 'qaction':
            result = iterClass.label === subscription.qaction;
            break;
          case 'qorg':
            result = iterClass.label === subscription.qorg;
            break;
          default:
            break;
        }
        return result;
      });
      const tree = cashbuzzQClass?.category_path.split('#').filter((path) => !!path);
      const classTree = tree?.slice(p.showStartNode ? startNode : startNode + 1, tree?.length);
      const roundedAmount = Number(subscription.amount_total.toFixed(2));

      if (classTree && cashbuzzQClass) {
        if (data.maxDeep < classTree.length) data.maxDeep = classTree.length;

        let chartPart: any = data;

        classTree.forEach((singleClass) => {
          const tempTreeClass = cashbuzzQClasses?.find((iterClass) => iterClass.label === singleClass);
          const tempChartPart = chartPart.children?.find((singleChartPart: any) => singleChartPart.class === singleClass);
          if (!tempTreeClass) return;

          if (tempChartPart) {
            tempChartPart.amount_total = Number((tempChartPart.amount_total + roundedAmount).toFixed(2));

            if (tempChartPart.value) tempChartPart.value = Number((tempChartPart.value + roundedAmount).toFixed(2));

            chartPart = tempChartPart;
          } else {
            helpIndex += 1;

            const newChartPart = {
              amount_total: subscription.amount_total,
              class: singleClass,
              currency: subscription.currency,
              id: helpIndex,
              name: currentLanguage === 'en' ? tempTreeClass.label_en : tempTreeClass.label_de,
              value: subscription.amount_total
            };
            if (chartPart.value) chartPart.value = undefined;
            if (!chartPart.children) chartPart.children = [];
            chartPart.children.push(newChartPart);
            chartPart = newChartPart;
          }
        });
      }
    });

    const colorsByIncomeType = distinctColors({
      count: data.children.length
    });

    const propagateColor = (el: any, color: string): void => {
      el.hex = color;
      if (el.children) {
        el.children.forEach((tempData: any) => {
          tempData.hex = color;

          if (tempData.children) {
            propagateColor(tempData, color);
          }
        });
      }
    };

    data.children.forEach((tempData, index) => {
      const color = colorsByIncomeType[index].hex();
      tempData.hex = color;
      if (tempData.children) {
        tempData.children.forEach((tempDataChild: any) => propagateColor(tempDataChild, color));
      }
    });
    setChartData(data);
  }, [p.startNode, p.showStartNode, p.qClass, p.subscriptions, cashbuzzQClasses]);


  return (
    <SunburstWithLegend
      chartWidth={ chartWidth }
      className="chart-block"
      data={ chartData }
      description={ p.description }
      height={ 300 }
      legendLabels={ {
        0: t('common.type')
      } }
      lightColors
      maxDeep={ chartData.maxDeep }
      title={ p.title }
    />
  );
};

export default CategorySunburstChart;
