/* eslint-disable global-require */
export const ICONS = {
  ALERT_CIRCLE: require('./icons/alert-circle.svg'),
  AUTO_RENEW: require('./icons/autorenew.svg'),
  BANK: require('./icons/bank.svg'),
  BOOK_OPEN_PAGE_VARIANT_OUTLINE: require('./icons/book-open-page-variant-outline.svg'),
  CALENDAR: require('./icons/calendar.svg'),
  CHART_BAR: require('./icons/chart-bar.svg'),
  CHART_TIMELINE_VARIANT: require('./icons/chart-timeline-variant.svg'),
  CHAT_OUTLINE: require('./icons/chat-outline.svg'),
  CHAT: require('./icons/chat.svg'),
  CHECKBOX_MARKED_CIRCLE: require('./icons/checkbox-marked-circle.svg'),
  CHEVRON_DOWN_BOX_OUTLINE: require('./icons/chevron-down-box-outline.svg'),
  CHEVRON_DOWN: require('./icons/chevron-down.svg'),
  CHEVRON_UP_BOX_OUTLINE: require('./icons/chevron-up-box-outline.svg'),
  CLOSE: require('./icons/close.svg'),
  COG: require('./icons/cog.svg'),
  COMMERZBANK_LOGO: require('./icons/Logo_Commerzbank_Squared.svg'),
  CONNECTION: require('./icons/connection.svg'),
  CROWN_OUTLINED: require('./icons/crown-outline.svg'),
  CURRENCY_EUR: require('./icons/currency-eur.svg'),
  CURRENCY_USD_OUTLINE: require('./icons/currency-usd-circle.svg'),
  DEUTSCHE_BANK: require('./icons/Logo_Deutsche_Bank_Squared.svg'),
  DOMAIN: require('./icons/domain.svg'),
  EMAIL: require('./icons/email.svg'),
  EMOTICON_EXCITED: require('./icons/emoticon-excited.svg'),
  EYE: require('./icons/eye.svg'),
  FILE_DOCUMENT_OUTLINE: require('./icons/file-document-outline.svg'),
  FLAG: require('./icons/flag.svg'),
  FLASH_ALERT: require('./icons/flash-alert.svg'),
  HASH: require('./icons/pound.svg'),
  HELP_CIRCLE_OUTLINE: require('./icons/help-circle-outline.svg'),
  HOME_CURRENCY: require('./icons/home-currency-usd.svg'),
  HOME: require('./icons/home.svg'),
  HVB_LOGO: require('./icons/Logo_HVB_Squared.svg'),
  INFORMATION: require('./icons/information.svg'),
  ING_LOGO: require('./icons/Logo_ING_Squared.svg'),
  IMAGE_FILTER: require('./icons/image-filter.svg'),
  LAYERS_TRIPLE_OUTLINE: require('./icons/layers-triple-outline.svg'),
  LOGOUT: require('./icons/logout.svg'),
  MAGNIFY: require('./icons/magnify.svg'),
  MAP_MARKER: require('./icons/map-marker.svg'),
  MENU: require('./icons/menu.svg'),
  METRICS: require('./icons/gauge.svg'),
  PEN: require('./icons/pen.svg'),
  PLUS_CIRCLE_OUTLINE: require('./icons/plus-circle-outline.svg'),
  POSTBANK_LOGO: require('./icons/Logo_Postbank_Squared.svg'),
  PRINT: require('./icons/print.svg'),
  ROCKET_LAUNCH: require('./icons/rocket-launch.svg'),
  ROCKET_OUTLINE: require('./icons/rocket-outline.svg'),
  SAFE_SQUARE_OUTLINE: require('./icons/safe-square-outline.svg'),
  SPARKASSE_LOGO: require('./icons/Logo_Sparkasse_Squared.svg'),
  STICKER_PLUS_OUTLINE: require('./icons/sticker-plus-outline.svg'),
  SUNGLASSES: require('./icons/sunglasses.svg'),
  SWAP_HORIZONTAL: require('./icons/swap-horizontal.svg'),
  TICKET: require('./icons/ticket.svg'),
  TICKET_PERCENT_OUTLINE: require('./icons/ticket-percent-outline.svg'),
  UNDO: require('./icons/undo.svg'),
  VOLKSBANK: require('./icons/Logo_Volksbank_Squared.svg')
};

export const IMAGES = {
  COMDIRECT_LOGO: require('./img/Logo_comdirect_Squared.png'),
  CONSORSBANK_LOGO: require('./img/Logo_Consorsbank_Squared.png'),
  DKB_LOGO: require('./img/Logo_DKB_Squared.jpg'),
  FLATEX_LOGO: require('./img/Logo_flatex_Squared.png'),
  HELLOBANK_LOGO: require('./img/Logo_Hellobank_Squared.png'),
  LOGO: require('./img/logo.png'),
  ONVISTA_LOGO: require('./img/Logo_Onvista_Bank_Squared.png'),
  SBROKER_LOGO: require('./img/Logo_S-broker_Squared.png'),
  SMARTBROKER_LOGO: require('./img/Logo_Smartbroker_Squared.png'),
  SPARDA_LOGO: require('./img/Logo_Sparda_Squared.png')
};

export const POCKET = {
  AMAZON_LOGO: require('./img/POCKET/Amazon-logo.png'),
  BACKGROUND: require('./img/POCKET/Pocket-temp.jpeg')
};
