import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePickerView, KeyboardDatePicker } from '@material-ui/pickers';
import { addMonths, format, isBefore, startOfMonth, subMonths } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { getCurrentDateFormat, getCurrentDateWithoutYearFormat } from '@/utils/format';

class DeLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date): string {
    return format(date, 'EEE MMM d', { locale: this.locale });
  }
}

const localeMap = {
  de: deLocale,
  en: enLocale
};

const localeUtilsMap = {
  de: DeLocalizedUtils,
  en: DateFnsUtils
};

export interface IDateRangePickerConfig {
  start?: Date;
  end?: Date;
  monthly?: boolean;
  onStartChange: (start: Date) => void;
  onEndChange: (end: Date) => void;
}

const DateRangePicker = (config: IDateRangePickerConfig): JSX.Element => {
  const classes = styles();
  const { t, i18n: i18nInstance } = useTranslation();

  const [startDate, setStartDate] = useState(config.start ? config.start : subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState(config.end ? config.end : addMonths(new Date(), 3));
  // const [minDate, setMinDate] = useState<Date>(startOfMonth(subMonths(new Date(), 3)));
  const dateFormat = config.monthly ? getCurrentDateWithoutYearFormat() : getCurrentDateFormat();
  const views: DatePickerView[] = config.monthly ? ['year', 'month'] : ['year', 'month', 'date'];
  const openTo = config.monthly ? 'month' : 'date';


  return (
    <MuiPickersUtilsProvider
      locale={ localeMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeMap)] }
      utils={ localeUtilsMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeUtilsMap)] }
    >
      <Grid
        className={ classes.pickerSection }
        container
        spacing={ 2 }
      >
        <Grid item>
          <KeyboardDatePicker
            format={ dateFormat }
            inputVariant="outlined"
            label={ t('profits_and_loss.start_date') }
            maxDate={ endDate }
            onChange={ (date): void => {
              const d = new Date(date?.toISOString() || '');
              setStartDate(d);
              config.onStartChange(d);
            } }
            openTo={ openTo }
            PopoverProps={ {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            } }
            value={ startDate }
            variant="inline"
            views={ views }
          />
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            format={ dateFormat }
            inputVariant="outlined"
            label={ t('profits_and_loss.end_date') }
            onChange={ (date): void => {
              const d = new Date(date?.toISOString() || '');
              setEndDate(d);
              config.onEndChange(d);
            } }
            openTo={ openTo }
            PopoverProps={ {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            } }
            value={ endDate }
            variant="inline"
            views={ views }
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
