import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    animation: '$appear 300ms',
    alignItems: 'flex-start',
    backgroundColor: theme.custom.colors.background
  },

  iconSection: {
    '& svg': {
      fill: theme.palette.text.primary
    }
  },

  contentSection: {
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: theme.spacing(2),
    marginRight: -theme.spacing(2),
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`
  },

  organizationLabel: {
    flexShrink: 1,
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
    wordWrap: 'break-word',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden'
  },

  contentBottom: {
    maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
    display: 'flex',
    'flex-wrap': 'nowrap',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  },

  categoryLabel: {
    fontSize: theme.custom.sizes.small,
    flexShrink: 1,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    'text-overflow': 'ellipsis',
    overflow: 'hidden'
  },

  amountSection: {
    justifySelf: 'flex-end',
    paddingLeft: theme.spacing(4),
    flexShrink: 0
    // '&.positive': {
    //   color: theme.palette.success.main
    // },

    // '&.negative': {
    //   color: theme.palette.error.main
    // }
  },

  '@keyframes appear': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}), { name: 'transaction-list-item' });
