import {
  Typography
} from '@material-ui/core';
import { Box } from '@material-ui/core';
import React from 'react';

import SVG from 'react-inlinesvg';
import styles from './styles';
import CircleIcon from '../CircleIcon';
import { ICONS } from '@/assets';
import useIntlNumberFormatter from '@/hooks/useIntlNumberFormatter';
import { RatingREGIOS } from '@/interfaces';
import { Nullable } from '@/types';


interface Props {
  name: string;
  address?: string;
  logoPath?: Nullable<string>;
  rating: RatingREGIOS;
  distance?: Nullable<number>;
}

const OrganizationCard = ({
  name,
  address,
  logoPath,
  distance,
  rating
}: Props): JSX.Element => {
  const classes = styles();
  const { formatFloat } = useIntlNumberFormatter();
  return (
    <div>
      <Box
        display="flex"
      >
        <div className={ classes.iconSection }>
          <CircleIcon
            icon={ logoPath || ICONS.BOOK_OPEN_PAGE_VARIANT_OUTLINE }
            rating={ rating }
          />
        </div>
        <div className={ classes.titleSection }>
          <Typography
            className={ classes.title }
            variant="h4"
          >
            { name }
          </Typography>
          { address && (
            <Typography
              className={ classes.address }
            >
              { address }
            </Typography>
          ) }
        </div>
      </Box>
      <Box ml={ 2 }>
        <Box
          alignItems="center"
          display="flex"
          mt={ 2 }
        >
          <SVG
            className={ classes.marker }
            src={ ICONS.MAP_MARKER }
            width={ 20 }
          />
          <Typography className={ classes.distance }>
            { `${distance ? formatFloat(Math.round(distance / 100) / 10) : '?'} km` }
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default OrganizationCard;
