import { Reducer } from 'redux';

import { CASHBUZZ_ACTIONS } from '@/constants/actions';
import { Attribute, CashbuzzData, DescriptionQClass, ExpenseWithLabelREGIOS } from '@/interfaces';
import { Nullable } from '@/types';

export type CashbuzzStateType = {
  attributes: Nullable<Attribute[]>;
  data: Nullable<CashbuzzData>;
  enforceAccountSelection: boolean;
  qclasses: DescriptionQClass[];
  expenseAffinity: ReadonlyArray<ExpenseWithLabelREGIOS>;
}

const initialState: CashbuzzStateType = {
  attributes: [],
  data: null,
  enforceAccountSelection: false,
  qclasses: [],
  expenseAffinity: []
};

const cashbuzzReducer: Reducer<CashbuzzStateType> = (state: CashbuzzStateType = initialState, action) => {
  switch (action.type) {
    case CASHBUZZ_ACTIONS.CLEAR: {
      return {
        ...initialState
      };
    }
    case CASHBUZZ_ACTIONS.SET_ATTRIBUTES: {
      return {
        ...state,
        attributes: action.data
      };
    }
    case CASHBUZZ_ACTIONS.SET_CASHBUZZ_POLICY: {
      return {
        ...state,
        data: action.data
      };
    }
    case CASHBUZZ_ACTIONS.SET_CASHBUZZ_QCLASSES: {
      return {
        ...state,
        qclasses: action.data
      };
    }
    case CASHBUZZ_ACTIONS.SET_ENFORCE_ACCOUNT_STATUS: {
      return {
        ...state,
        enforceAccountSelection: action.data
      };
    }
    case CASHBUZZ_ACTIONS.SET_EXPENSES_AFFINITY: {
      const labelNotFound: DescriptionQClass = {
        label: 'grxf',
        label_path: '',
        description: 'not found',
        category_path: '',
        label_en: 'Unknown',
        label_de: 'Unbekannt',
        order_nr: Number.MAX_SAFE_INTEGER
      };
      const expenses = action.data as ReadonlyArray<ExpenseWithLabelREGIOS>;
      const expensesWithLabels = expenses.map((expense) => ({
        ...expense,
        label: state.qclasses.find((label) => label.label === expense.key) || labelNotFound
      }));
      expensesWithLabels.sort((a, b) => (a.label.order_nr || 0) - (b.label.order_nr || 0));
      return {
        ...state,
        expenseAffinity: expensesWithLabels
      };
    }
    default:
      return state;
  }
};

export default cashbuzzReducer;
