import { Paper, Typography, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import styles from './styles';
import BarChart, { IBarChartConfig } from '@/components/BarChart';
import LineChart from '@/components/LineChart';
import { Metric } from '@/declarations/material-ui';
import i18n from '@/i18n';
import { Insight } from '@/interfaces';
import APIService from '@/services/APIService';
import { getCurrentLanguage, to6391Format } from '@/utils/languages';
import { IMetricsConfiguration } from '@/views/DashboardView/pages/Metrics';

interface Props {
  metric: Metric;
}

const Card = (p: Props): JSX.Element => {
  const theme = useTheme();
  const classes = styles();
  const colors = theme.custom.colors as any;
  const isEnglish = getCurrentLanguage() === 'en';
  const m = p.metric;
  const title = isEnglish ? m.title_en : m.title_de;
  const text = isEnglish ? m.text_en : m.text_de;
  const unit = isEnglish ? m.unit_en : m.unit_de;
  const iconColor = colors[m.icon_color];

  const [insight, setInsight] = useState<number>(0);
  const [insights, setInsights] = useState<Insight[]>([]);

  const formatValue = (v: number): string => {
    const fractionDigits = m.value_format === 'amount' ? 2 : 0;
    v = m.value_format === 'percentage' ? Math.round(v * 100) : v;
    return Intl.NumberFormat(to6391Format(i18n.language), {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    }).format(v);
  };

  const getValueColor = (v: number): string => {
    let color = v > 0 ? theme.custom.colors.amountPositive : theme.custom.colors.amountNegative;
    color = v === 0 ? theme.custom.colors.neutral : color;
    color = m.value_color === 'amount' ? color : colors[m.value_color];
    return color;
  };

  const render = (): JSX.Element => {
    if (m.display_type === 'value_card') {
      const paragraphs = text.split('{value}');
      return (
        <>
          <Typography className={ classes.paragraph }>{ paragraphs[0] }</Typography>
          <Typography>
            <span style={ { color: getValueColor(insight), fontSize: theme.custom.sizes.large } }>{ formatValue(insight) }</span>
            { ' ' }
            <span style={ { fontSize: theme.custom.sizes.small } }>{ unit }</span>
          </Typography>
          <Typography className={ classes.paragraph }>{ paragraphs[1] }</Typography>
        </>
      );
    }

    const multiplier = m.multiply_values_by_m1 ? -1 : 1;
    const config: IBarChartConfig = {
      series: {
        color: colors[m.value_color],
        data: insights.map((i) => ({ x: new Date(i.date).getTime(), y: i.value * multiplier }))
      },
      xAxis: {
        format: (x: number): string => format(x, 'MMM, yy', { locale: isEnglish ? enLocale : deLocale })
      },
      yAxis: {
        format: (y: number): string => {
          const formatted = Intl.NumberFormat(to6391Format(i18n.language)).format(y);
          return m.value_format === 'percentage' ? `${formatted}${unit}` : `${unit}${formatted}`;
        }
      }
    };

    if (m.display_type === 'series_bar') {
      return (
        <BarChart config={ config } />
      );
    }
    return (
      <LineChart config={ config } />
    );
  };

  useEffect(() => {
    const serializedConfig = localStorage.getItem('metrics');
    const config: IMetricsConfiguration = serializedConfig ? JSON.parse(serializedConfig) : {};
    const startDate = config.startDate ? new Date(config.startDate) : undefined;
    const endDate = config.endDate ? new Date(config.endDate) : undefined;
    const quantization = config.quantization ? config.quantization : 'month';
    APIService.insights.get(m.insight, startDate, endDate, quantization)
      .then((response) => {
        if (m.result_type === 'value') {
          const { value } = response.data[0];
          const multiplier = value !== 0 && m.multiply_values_by_m1 ? -1 : 1;
          setInsight(value * multiplier);
        } else {
          setInsights(response.data);
        }
      });
  }, [m.insight, m.multiply_values_by_m1, m.result_type]);

  return (
    <Paper className={ classes.root }>
      <Typography
        className={ classes.title }
        variant="h6"
      >
        { title }
      </Typography>
      <div
        className={ classes.icon }
        style={ { color: iconColor } }
      >
        <SVG
          src={ m.icon }
        />
      </div>
      { render() }
    </Paper>
  );
};

export default Card;
