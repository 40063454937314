import { Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaSeries, Hint, HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import styles from './styles';
import { formatDateWithoutYear } from '@/utils/format';
import { to6391Format } from '@/utils/languages';


interface IPoint {
  x: number;
  y: number;
}

interface ISeries {
    color?: string;
    data: IPoint[];
}

interface IXAxisConfig {
  format?: (v: number) => string;
  values: number[];
}

export interface IAreaChartConfig {
  hint?: string;
  min: number;
  max: number;
  series: ISeries[];
  xAxis: IXAxisConfig;
}

interface Props {
  width?: number;
  config?: IAreaChartConfig;
}

const AreaChart = (p: Props): JSX.Element => {
  const theme = useTheme();
  const classes = styles();
  const c: IAreaChartConfig = p.config
    ? p.config
    : {
      min: 0,
      max: 0,
      series: [],
      xAxis: { values: [] }
    };
  const { i18n: i18nInstance } = useTranslation();
  const hintText = c.hint ? c.hint : '';
  const [hintValue, setHintValue] = useState<{ x: number | string | Date; y: number | string | Date }>();
  const xAxisFormat = (value: number): string => formatDateWithoutYear(new Date(value).toISOString());
  const yAxisFormat = (value: number): string => new Intl.NumberFormat(to6391Format(i18nInstance.language), { style: 'currency', minimumFractionDigits: 0, currency: 'EUR' }).format(Number(value));
  const colors: string[] = [theme.custom.colors.neutral, theme.palette.primary.main];
  const renderAreaSeries = (s: ISeries, i: number): JSX.Element => (
    <AreaSeries
      key={ `area-series-${i}` }
      color={ s.color ? s.color : colors[i] }
      data={ s.data }
      opacity={ 0.4 }
    />
  );

  const renderLineMarkSeries = (s: ISeries, i: number): JSX.Element => (
    <LineMarkSeries
      key={ `line-mark-series-${i}` }
      color={ s.color ? s.color : colors[i] }
      data={ s.data }
      onValueMouseOver={ (value): void => {
        setHintValue({ x: value.x, y: value.y });
      } }
    />
  );

  const series: JSX.Element[] = [];
  c.series.forEach((s, i) => {
    series.push(renderAreaSeries(s, i));
    series.push(renderLineMarkSeries(s, i));
  });

  return (
    <XYPlot

      height={ 300 }
      margin={ { left: 100, bottom: 50 } }
      onMouseLeave={ (): void => { setHintValue(undefined); } }
      width={ p.width || 100 }
      yDomain={ [c.min, c.max + 5000] }
    >
      <HorizontalGridLines />
      <XAxis
        tickFormat={ xAxisFormat }
        tickLabelAngle={ -90 }
        tickTotal={ 40 }
        tickValues={ c.xAxis.values }
      />
      <YAxis
        tickFormat={ yAxisFormat }
        tickTotal={ 10 }
      />
      { series }
      {
        hintValue && (
          <Hint value={ hintValue }>
            <div className={ classes.hint }>
              <Typography variant="subtitle2">
                { hintText }
              </Typography>
              <Typography variant="subtitle2">
                { new Intl.NumberFormat(to6391Format(i18nInstance.language), { style: 'currency', currency: 'EUR' }).format(Number(hintValue.y)) }
              </Typography>
            </div>
          </Hint>
        )
      }
    </XYPlot>
  );
};
export default AreaChart;
