import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  pickerSection: {
    marginBottom: 24,
    '& > *:not(:last-child)': {
      marginRight: 8
    }
  }
}), { name: 'date-range-picker' });
