import { Typography } from '@material-ui/core';
import React from 'react';

import styles from './styles';
import DateRangePicker, { IDateRangePickerConfig } from '@/components/DateRangePicker';

interface Props {
  title: string;
  dateRangePicker?: IDateRangePickerConfig;
}


const PageHeader = (p: Props): JSX.Element => {
  const classes = styles();

  const renderDateRangePicker = (): JSX.Element | null => {
    if (p.dateRangePicker) {
      const config = p.dateRangePicker;
      return (
        <div>
          <DateRangePicker
            end={ config.end }
            monthly={ config.monthly }
            onEndChange={ config.onEndChange }
            onStartChange={ config.onStartChange }
            start={ config.start }
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div>
        <Typography
          className={ classes.title }
          variant="h4"
        >
          { p.title }
        </Typography>
      </div>
      { renderDateRangePicker() }
    </div>
  );
};

export default PageHeader;
