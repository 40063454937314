import React, { useEffect, useState } from 'react';
import AreaChart, { IAreaChartConfig } from '@/components/AreaChart';
import { Account, LiquidityChartData, Transaction } from '@/interfaces';
import APIService from '@/services/APIService';
import { getLiquidityData } from '@/utils/charts';

interface Props {
  hint: string;
  parent: React.RefObject<HTMLDivElement>;
  startDate: Date;
  endDate: Date;
  accounts: Account[];
}

const LiquidityChart = (p: Props): JSX.Element => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [data, setLiquidityData] = useState<LiquidityChartData>({ data: [], min: 0, max: 0 });
  const [chartConfig, setChartConfig] = useState<IAreaChartConfig>();

  useEffect(() => {
    const loadTransactions = (pageNumber: number, tx: Transaction[]): void => {
      APIService
        .transaction
        .getAllByPeriod(p.startDate, p.endDate, pageNumber, 1000000)
        .then((response) => {
          const page = response.data.transactions;
          tx = tx.concat(page);
          if (response.data.paging.totalCount === pageNumber || page.length === 0) {
            setTransactions(tx);
            setLiquidityData(getLiquidityData(tx, p.startDate, p.endDate, p.accounts));
          } else {
            loadTransactions(pageNumber + 1, tx);
          }
        });
    };

    loadTransactions(1, []);
  }, [p.startDate, p.endDate, p.accounts]);

  useEffect(() => {
    const futureIndex = data.data.findIndex((singleData) => singleData.predicted) + 1 || data.data.length;
    const d1 = data.data.slice(futureIndex - 1, data.data.length).map((period) => ({
      x: period.start.getTime(),
      y: period.balance
    }));
    const d2 = data.data.slice(0, futureIndex).map((period) => ({
      x: period.start.getTime(),
      y: period.balance
    }));
    const config: IAreaChartConfig = {
      hint: p.hint, // t('overview.sme.liquidity_chart.hint.weekly_value')
      min: data.min,
      max: data.max,
      series: [
        {
          data: d1
        },
        {
          data: d2
        }
      ],
      xAxis: {
        values: data.data.map((d) => d.start.getTime())
      }
    };
    setChartConfig(config);
  }, [data, p.hint]);

  useEffect(() => {
    const resizeHandler = (): void => {
      setLiquidityData(getLiquidityData(transactions, p.startDate, p.endDate, p.accounts));
    };
    window.addEventListener('resize', resizeHandler);
    return (): void => window.removeEventListener('resize', resizeHandler);
  });

  return (
    <>
      {
        chartConfig && (
          <AreaChart
            config={ chartConfig }
            width={ p.parent.current?.offsetWidth }
          />
        )
      }
    </>
  );
};

export default LiquidityChart;
