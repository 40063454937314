import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PaginationEl from '@material-ui/lab/Pagination';

import { addMonths, subMonths } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { useSelector } from 'react-redux';
import styles from './styles';
import { loading, closeLoading } from '@/components/CustomLoader';
import { IDateRangePickerConfig } from '@/components/DateRangePicker';
import { showNotification } from '@/components/Notification';
import PageHeader from '@/components/PageHeader';
import CustomTableBody from '@/components/Table/Body';
import CustomTableRow, { IRow } from '@/components/Table/Row';
import { Pagination, Organization } from '@/interfaces';
import { AppState } from '@/reducers';
import APIService from '@/services/APIService';
import { getCurrentLanguage } from '@/utils/languages';


const Organizations = (): JSX.Element => {
  const classes = styles();
  const { t } = useTranslation();
  const cashbuzzQClasses = useSelector((state: AppState) => state.cashbuzz.qclasses);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [startDate, setStartDate] = useState(subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 3));

  const organizationsHelper = useRef<{ [key: string]: Organization }>({});
  const loadingIdRef = useRef<null|number>(null);

  const dateRangePickerConfig: IDateRangePickerConfig = {
    start: startDate,
    end: endDate,
    onStartChange: (d) => setStartDate(d),
    onEndChange: (d) => setEndDate(d)
  };

  const fetchTransactions = useCallback(async (page: number): Promise<void> => {
    setIsLoading(true);
    try {
      const { data } = await APIService.transaction.getAllByPeriod(startDate, endDate, page);
      const ids = data.transactions.map((tx) => tx.org_id);
      const orgs: string[] = ids.filter((id) => id && !organizationsHelper.current[id]) as string[];
      if (orgs.length) {
        const { data: organizations } = await APIService.description.getOrganizations(orgs);

        organizations.forEach((org) => {
          organizationsHelper.current[org.id] = org;
        });
      }
      const list: Organization[] = [];
      const transactions: Map<string, Organization> = new Map();
      data.transactions.forEach((tx) => {
        if (tx.org_id) transactions.set(tx.org_id, organizationsHelper.current[tx.org_id]);
      });
      transactions.forEach((org) => list.push(org));

      setOrganizationList(list);
      setPagination(data.paging);
    } catch {
      showNotification({ content: t('common.internal_error'), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [t, startDate, endDate]);

  useEffect(() => {
    fetchTransactions(activePage);
  }, [activePage, startDate, endDate, fetchTransactions]);

  useEffect(() => {
    if (isLoading) {
      loadingIdRef.current = loading({ elementSelector: 'main .dashboard-view-element-root' });
    } else {
      closeLoading(loadingIdRef.current);
      loadingIdRef.current = null;
    }
  }, [isLoading]);

  useEffect(() => (): void => {
    if (loadingIdRef.current) {
      closeLoading(loadingIdRef.current);
    }
  }, []);

  const mapToCustomRow = (o: Organization): IRow => {
    const currentLanguage = getCurrentLanguage();
    const qClass = cashbuzzQClasses.find((qclass) => qclass.label === o.qorg);
    const industry = currentLanguage === 'en' ? qClass?.label_en : qClass?.label_de;
    return {
      id: o.id,
      detail: true,
      values: [
        {
          image: true,
          value: o.logo_sqrd
        },
        {
          value: o.name
        },
        {
          value: o.full_name
        },
        {
          value: industry || ''
        }
      ]
    };
  };

  return (
    <div className={ `${classes.root} dashboard-view-element-root` }>
      <div>
        <PageHeader
          dateRangePicker={ dateRangePickerConfig }
          title={ t('organizations.title') }
        />

        <div className={ classes.sectionContent }>
          <TableContainer
            component={ Paper }
            style={ { overflowY: 'hidden' } }
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    { t('organizations.table.logo') }
                  </TableCell>
                  <TableCell>
                    { t('organizations.table.short_name') }
                  </TableCell>
                  <TableCell>
                    { t('organizations.table.full_name') }
                  </TableCell>
                  <TableCell>
                    { t('organizations.table.industry') }
                  </TableCell>
                </TableRow>
              </TableHead>
              <CustomTableBody
                customEmptyText={ t('tables.no_data') }
                empty={ !organizationList.length }
                hasPagination={ !!pagination?.pageCount }
                loading={ isLoading }
              >
                {
                  organizationList.map((o) => (
                    <CustomTableRow
                      key={ o.id }
                      row={ mapToCustomRow(o) }
                    >
                      <Grid
                        container
                        spacing={ 2 }
                      >
                        <Grid
                          item
                          xs={ 8 }
                        >
                          <div>
                            <Typography
                              className="subscription-detail-title"
                              variant="subtitle2"
                            >
                              { t('organizations.table.legal_name') }
                            </Typography>
                            { o.legal_name ? o.legal_name : t('common.not_available') }
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={ 4 }
                        >
                          <div>
                            <Typography
                              className="subscription-detail-title"
                              variant="subtitle2"
                            >
                              { t('organizations.table.brand_name') }
                            </Typography>
                            { o.brand_name ? o.brand_name : t('common.not_available') }
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={ 4 }
                        >
                          <div>
                            <Typography
                              className="subscription-detail-title"
                              variant="subtitle2"
                            >
                              { t('organizations.table.address') }
                            </Typography>
                            { o.address ? o.address.address : t('common.not_available') }
                          </div>
                        </Grid>
                      </Grid>
                    </CustomTableRow>
                  ))
                }
              </CustomTableBody>
            </Table>
            {
              pagination && pagination.pageCount > 1
            && (
              <PaginationEl
                color="primary"
                count={ pagination?.pageCount || 0 }
                onChange={ (event, page): void => { setActivePage(page); } }
              />
            )
            }
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Organizations;
