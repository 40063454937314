import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { loading, closeLoading } from '@/components/CustomLoader';
import FinApiWebForm, { FinApiWebFormEventType } from '@/components/FinApiWebForm';
import { showNotification } from '@/components/Notification';
import { ImportBankConnectionResponseData } from '@/interfaces';
import { AppState } from '@/reducers';
import APIService from '@/services/APIService';

interface Props {
  bankId: number;
  connect?: boolean;
  update?: boolean;
  onComplete?: (message?: string) => void | Promise<void>;
}

const BankConnectionForm = ({
  bankId,
  connect = false,
  onComplete = (): void => {},
  update = false
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const user = useSelector((state: AppState) => state.auth.user);
  const [formId, setFormId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const loadingIdRef = useRef<null|number>(null);

  // TODO: Deprecate this state.
  const [finAPIConfig] = useState<ImportBankConnectionResponseData>();


  const onWebFormEvent = (type: FinApiWebFormEventType): void => {
    switch (type) {
      case FinApiWebFormEventType.Complete:
        onComplete();
        break;
      case FinApiWebFormEventType.Abort:
        setFormId('');
        break;
      default:
        // TODO: Let the user know something has failed.
        break;
    }
  };

  useEffect(() => {
    const getFinAPIConfig = async (): Promise<void> => {
      try {
        if (update) {
          const { data: response } = await APIService
            .bankConnections
            .updateBankConnection2(bankId);
          if (response.url) setFormId(response.id);
        } else if (connect) {
          // TODO: Ask about this use case.
          // const { data } = await APIService.bankConnections.connectInterface(bankId, bankInterface);
          // finApiConfigRef.current = data;
          // setFinAPIConfig(data);
        } else {
          const { data: response } = await APIService
            .bankConnections
            .importBankConnection2({
              bank: {
                id: bankId
              }
            });
          setFormId(response.id);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 423) {
            showNotification({ content: t('update_bank_connection.423_error'), type: 'error' });
          } else {
            showNotification({ content: t('add_new_bank_connection.connection_failure'), type: 'error' });
          }
        } else {
          showNotification({ content: t('common.internal_error'), type: 'error' });
        }
      } finally {
        setIsLoading(false);
      }
    };

    getFinAPIConfig();
  }, [bankId, connect, t, update]);

  useEffect(() => {
    if (finAPIConfig && !finAPIConfig.uri && user) {
      APIService.user.triggerTransactionRefetch(user.id);
      showNotification({ content: t('update_bank_connection.up_to_date'), type: 'success' });
    }

    setTimeout(() => {
      dispatchEvent(new Event('load'));
    }, 500);

    setIsLoading(false);
  }, [finAPIConfig, t, user]);

  useEffect(() => {
    if (isLoading) {
      loadingIdRef.current = loading({ elementSelector: 'main .dashboard-view-element-root' });
    } else {
      closeLoading(loadingIdRef.current);
      loadingIdRef.current = null;
    }
  }, [isLoading]);

  useEffect(() => (): void => {
    if (loadingIdRef.current) {
      closeLoading(loadingIdRef.current);
    }
  }, []);

  return (
    <div style={ { alignSelf: 'center', display: 'flex', minWidth: '100%' } }>
      { formId && (
        <FinApiWebForm
          id={ formId }
          onEvent={ onWebFormEvent }
        />
      ) }
    </div>
  );
};

export default BankConnectionForm;
