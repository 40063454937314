import { load } from '@finapi/web-form';
import React, { useCallback } from 'react';

interface Props {
  id: string;
  onEvent: (type: FinApiWebFormEventType) => void;
}

export enum FinApiWebFormEventType {
  Abort,
  Complete,
  Fail
}

const FinApiWebForm = (p: Props): JSX.Element => {
  const createWebForm = useCallback(async (target: HTMLElement) => {
    const onAbort = (): void => {
      p.onEvent(FinApiWebFormEventType.Abort);
    };

    const onFail = (): void => {
      p.onEvent(FinApiWebFormEventType.Fail);
    };

    const onComplete = (): void => {
      p.onEvent(FinApiWebFormEventType.Complete);
    };


    load(target, {
      token: p.id
      // targetUrl: 'https://webform-sandbox.finapi.io'
    }, { onAbort, onFail, onComplete });
  }, [p]);


  const containerRef = (container: HTMLDivElement): void => {
    if (container) {
      createWebForm(container);
    }
  };

  return (
    <div ref={ containerRef } />
  );
};

export default FinApiWebForm;
