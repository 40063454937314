import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%'
  },

  rootTitle: {
    marginLeft: theme.spacing(2)
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8
  },

  emptyListMessage: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}), { name: 'transaction-list' });
