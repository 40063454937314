import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from './Header';
import RegiosExpensesCard, { RegiosExpensesCardSkeleton } from './RegiosExpensesCard';
import styles from './styles';
import { ROUTES } from '@/constants';
import { DescriptionQClass, ExpenseKeyREGIOS, ExpenseWithLabelREGIOS } from '@/interfaces';
import { AppState } from '@/reducers';
import APIService from '@/services/APIService';
import { expenseArrayToExpense } from '@/utils/regios';

type SpendingsAffinityListProps = React.ComponentPropsWithoutRef<'ul'>;

const SpendingsAffinityList: React.FC<SpendingsAffinityListProps> = () => {
  const classes = styles();
  const { i18n } = useTranslation();
  const [cards, setCards] = useState<ReadonlyArray<ExpenseWithLabelREGIOS>>([]);
  const labels = useSelector((state: AppState) => state.cashbuzz.qclasses);
  useEffect(() => {
    (async (): Promise<void> => {
      const { data } = await APIService.user.getExpensesAffinity();
      const keys = Object.keys(data) as ExpenseKeyREGIOS[];
      const labelNotFound: DescriptionQClass = {
        label: 'grxf',
        label_path: '',
        description: 'not found',
        category_path: '',
        label_en: 'Unknown',
        label_de: 'Unbekannt',
        order_nr: Number.MAX_SAFE_INTEGER
      };
      const gauges = keys.map((key) => expenseArrayToExpense(key, data[key]));
      const gaugedataWithLabels = gauges.map((gauge) => ({
        ...gauge,
        label: labels.find((label) => label.label === gauge.key) || labelNotFound
      }));
      gaugedataWithLabels.sort((a, b) => (a.label.order_nr || 0) - (b.label.order_nr || 0));
      setCards(gaugedataWithLabels);
    })();
  }, [labels]);

  const labelKey = useMemo(
    () => `label_${i18n.language}` as 'label_de' | 'label_en',
    [i18n.language]
  );
  const empty = [1, 2, 3, 4, 5].map((idx) => (
    <li key={ idx }>
      <RegiosExpensesCardSkeleton animation="wave" />
    </li>
  ));
  return (
    <>
      <Header />
      <Box
        className={ classes.list }
        component="ul"
        mt={ 2 }
      >
        { cards.length
          ? cards.map(
            ({
              key,
              label,
              totalAmount,
              ...ratings
            }) => (
              <Box
                key={ key }
                component="li"
              >
                <Link to={ `${ROUTES.AUTHORIZED.REGIOS_TRANSACTIONS}/${key}` }>
                  <RegiosExpensesCard
                    title={ label[labelKey] }
                    totalAmount={ totalAmount }
                    { ...ratings }
                  />
                </Link>
              </Box>
            )
          )
          : empty }
      </Box>
    </>
  );
};

export default SpendingsAffinityList;
