
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import DateFnsUtils from '@date-io/date-fns';
import {
  Paper,
  Divider,
  Typography,
  useTheme
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  addMonths,
  isAfter,
  isFuture,
  isSameMonth,
  format,
  parse,
  subMonths
} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import KPICards from '../components/KPICards';
import LiquidityChart from '../components/LiquidityChart';
import Metrics from '../components/Metrics';
import { loading, closeLoading } from '@/components/CustomLoader';
import { CATEGORIZATION } from '@/constants';
import { Account, Suggestion, Pagination, FinanceGroup } from '@/interfaces';
import APIService from '@/services/APIService';
import { getCurrentDateFormat } from '@/utils/format';
import Hints from '@/views/DashboardView/components/Hints';
import StatusBar from '@/views/DashboardView/components/StatusBar';

class DeLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date): string {
    return format(date, 'EEE MMM d', { locale: this.locale });
  }
}

const localeMap = {
  de: deLocale,
  en: enLocale
};

const localeUtilsMap = {
  de: DeLocalizedUtils,
  en: DateFnsUtils
};

interface Props {
  accounts: Account[];
}

const Liquidity = ({ accounts }: Props): JSX.Element => {
  const classes = styles();
  const theme = useTheme();
  const { t, i18n: i18nInstance } = useTranslation();
  const [liquidityMinBookingDate, setLiquidityMinBookingDate] = useState(subMonths(new Date(), 5));
  const [liquidityMaxBookingDate, setLiquidityMaxBookingDate] = useState(addMonths(new Date(), 3));
  const [liquidityOldestTransactionDate, setLiquidityOldestTransactionDate] = useState<Date>();
  const [financeGroupsChartData, setFinanceGroupsChartData] = useState<{
    incomes: {
      label: string;
      list: FinanceGroup[];
    }[];
    expenses: {
      label: string;
      list: FinanceGroup[];
    }[]; }>();
  const [financeGroupsChartFutureData, setFinanceGroupsChartFutureData] = useState<{
      incomes: {
        label: string;
        list: FinanceGroup[];
      }[];
      expenses: {
        label: string;
        list: FinanceGroup[];
      }[]; }>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentHints, setCurrentHints] = useState<Suggestion[]>([]);
  const [hintsPagination, setHintsPagination] = useState<Pagination>();
  const [hintsActivePage, setHintsActivePage] = useState(1);
  const [statusBarSuggestions, setStatusBarSuggestions] = useState<Suggestion[]>([]);
  const elementRef = useRef<HTMLDivElement>(null);
  const isInitRef = useRef(true);
  const loadingIdRef = useRef<null|number>(null);

  const fetchSuggestions = useCallback(async (page: number, standalone = true): Promise<void> => {
    if (standalone) {
      setIsLoading(true);
    }
    try {
      const { data } = await APIService.suggestions.getAll(page);

      setStatusBarSuggestions(data.hints.filter((singleHint) => singleHint.hint_location === 'status_bar'));
      setHintsPagination(data.paging);
      setCurrentHints(data.hints.filter((singleHint) => singleHint.hint_location === 'hint_box'));
    } catch (error) {
      if (standalone) {
        // showNotification({ content: t('common.internal_error'), type: 'error' });
      } else {
        throw error;
      }
    } finally {
      if (standalone) {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const minDate = subMonths(new Date(), 5);
        const oldestTransaction = await APIService.transaction.getOldestTransaction();
        if (oldestTransaction) {
          const oldestTransactionDate = parse(oldestTransaction.valueDate, 'yyyy-MM-dd', new Date());
          if (isAfter(oldestTransactionDate, minDate)) {
            setLiquidityMinBookingDate(oldestTransactionDate);
          }
          setLiquidityOldestTransactionDate(oldestTransactionDate);
        } else {
          setLiquidityOldestTransactionDate(undefined);
        }
      } catch {
        // showNotification({ content: t('common.internal_error'), type: 'error' });
      }
    };

    const fetchFinanceGroups = async (): Promise<void> => {
      try {
        const { data } = await APIService.user.getFinanceGroups('general');

        const filteredFinanceGroups = data.finance_groups.filter(
          (singleGroup) => ![
            CATEGORIZATION.FINANCIAL_GROUP_FOR_INCOME_CONSUMER,
            CATEGORIZATION.FINANCIAL_GROUP_FOR_EXPENSES_CONSUMER
          ].includes(singleGroup.label)
        );

        const mappedData = filteredFinanceGroups.reduce<{ incomes: any; expenses: any }>((previusValue, currentValue) => {
          const classTree = currentValue.path.split('#');
          let type;

          if (classTree.includes(CATEGORIZATION.FINANCIAL_GROUP_FOR_INCOME_CONSUMER)) {
            type = 'income';
          } else if (classTree.includes(CATEGORIZATION.FINANCIAL_GROUP_FOR_EXPENSES_CONSUMER)) {
            type = 'expenses';
          } else {
            return previusValue;
          }

          switch (type) {
            case 'income':
              if (previusValue.incomes[currentValue.label]) {
                previusValue.incomes[currentValue.label].list.push(currentValue);
              } else {
                previusValue.incomes[currentValue.label] = {
                  label: currentValue.label,
                  list: [currentValue]
                };
              }
              break;
            case 'expenses':
              if (previusValue.expenses[currentValue.label]) {
                previusValue.expenses[currentValue.label].list.push(currentValue);
              } else {
                previusValue.expenses[currentValue.label] = {
                  label: currentValue.label,
                  list: [currentValue]
                };
              }
              break;
            default:
          }

          return previusValue;
        }, { incomes: {}, expenses: {} });

        const pastAndCurrentIncomes = Object.values(mappedData.incomes).map((value: any) => ({
          label: value.label,
          list: value.list.filter((singleValue: any) => !isFuture(parse(singleValue.month, 'yyyy-MM', new Date())) && !isSameMonth(parse(singleValue.month, 'yyyy-MM', new Date()), new Date()))
        }));

        const futureIncomes = Object.values(mappedData.incomes).map((value: any) => ({
          label: value.label,
          list: value.list.filter((singleValue: any) => isFuture(parse(singleValue.month, 'yyyy-MM', new Date())) || isSameMonth(parse(singleValue.month, 'yyyy-MM', new Date()), new Date()))
        }));

        const pastAndCurrentExpenses = Object.values(mappedData.expenses).map((value: any) => ({
          label: value.label,
          list: value.list.filter((singleValue: any) => !isFuture(parse(singleValue.month, 'yyyy-MM', new Date())) && !isSameMonth(parse(singleValue.month, 'yyyy-MM', new Date()), new Date()))
        }));

        const futureExpenses = Object.values(mappedData.expenses).map((value: any) => ({
          label: value.label,
          list: value.list.filter((singleValue: any) => isFuture(parse(singleValue.month, 'yyyy-MM', new Date())) || isSameMonth(parse(singleValue.month, 'yyyy-MM', new Date()), new Date()))
        }));

        setFinanceGroupsChartData({ expenses: pastAndCurrentExpenses, incomes: pastAndCurrentIncomes });
        setFinanceGroupsChartFutureData({ expenses: futureExpenses, incomes: futureIncomes });
      } catch {
        // showNotification({ content: t('common.internal_error'), type: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAll = async (): Promise<void> => {
      setIsLoading(true);
      await fetchData();
      await fetchFinanceGroups();
      await fetchSuggestions(1, false);
      setIsLoading(false);
      isInitRef.current = false;
    };

    fetchAll();
  }, [accounts, fetchSuggestions]);

  useEffect(() => {
    if (isLoading) {
      loadingIdRef.current = loading({ elementSelector: 'main .dashboard-view-element-root' });
    } else {
      closeLoading(loadingIdRef.current);
      loadingIdRef.current = null;
    }
  }, [isLoading]);

  useEffect(() => (): void => {
    if (loadingIdRef.current) {
      closeLoading(loadingIdRef.current);
    }
  }, []);

  return (
    <div
      ref={ elementRef }
      className={ `${classes.root} dashboard-view-element-root` }
    >
      <StatusBar
        accounts={ accounts }
        messages={ statusBarSuggestions }
        type="GENERAL"
      />
      <div className={ `${classes.root}-chart-section section` }>
        <Metrics />
        <div className="liquidity-chart">
          <Typography
            className="section-title"
            variant="h4"
          >
            { t('overview.general.liquidity_chart.title') }
          </Typography>
          { accounts.length ? (
            <Paper>
              <div className={ classes.liquidityPickerSection }>
                <MuiPickersUtilsProvider
                  locale={ localeMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeMap)] }
                  utils={ localeUtilsMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeUtilsMap)] }
                >
                  <KeyboardDatePicker
                    autoOk
                    format={ getCurrentDateFormat() }
                    inputVariant="outlined"
                    label={ t('overview.general.liquidity_chart.start_date') }
                    maxDate={ liquidityMaxBookingDate }
                    minDate={ liquidityOldestTransactionDate }
                    onChange={ (date): void => { setLiquidityMinBookingDate(new Date(date || '')); } }
                    placeholder={ getCurrentDateFormat().toUpperCase() }
                    readOnly={ false }
                    value={ liquidityMinBookingDate }
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider
                  locale={ localeMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeMap)] }
                  utils={ localeUtilsMap[(i18nInstance.languages[0].split('-')[0] as keyof typeof localeUtilsMap)] }
                >
                  <KeyboardDatePicker
                    autoOk
                    format={ getCurrentDateFormat() }
                    inputVariant="outlined"
                    label={ t('overview.general.liquidity_chart.end_date') }
                    minDate={ liquidityMinBookingDate }
                    onChange={ (date): void => { setLiquidityMaxBookingDate(new Date(date || '')); } }
                    placeholder={ getCurrentDateFormat().toUpperCase() }
                    readOnly={ false }
                    value={ liquidityMaxBookingDate }
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <LiquidityChart
                accounts={ accounts }
                endDate={ liquidityMaxBookingDate }
                hint={ t('overview.general.liquidity_chart.hint.weekly_value') }
                parent={ elementRef }
                startDate={ liquidityMinBookingDate }
              />
            </Paper>
          ) : (<p>{ t('overview.general.liquidity_chart.no_data') }</p>) }
        </div>
      </div>
      <Divider style={ { marginTop: '40px', marginBottom: '40px' } } />
      <KPICards
        accounts={ accounts }
        financeGroupsChartData={ financeGroupsChartData }
        financeGroupsChartFutureData={ financeGroupsChartFutureData }
      />
      { currentHints.length > 0 && (
        <>
          <Divider style={ { marginTop: '40px', marginBottom: '40px' } } />
          <Hints
            activePage={ hintsActivePage }
            hints={ currentHints }
            isLoading={ isLoading }
            onPageChange={ (page): void => setHintsActivePage(page) }
            pagination={ hintsPagination }
          />
        </>
      ) }
      { /* {
        !!signals.length && (
          <div className={ `${classes.root}-table-section section` }>
            <Typography
              className="section-title"
              variant="h4"
            >
              { t('overview.general.signals.title') }
            </Typography>
            <TableContainer
              component={ Paper }
              style={ { overflowY: 'hidden' } }
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      { t('overview.general.signals.table.columns.date') }
                    </TableCell>
                    <TableCell>
                      { t('overview.general.signals.table.columns.partner') }
                    </TableCell>
                    <TableCell>
                      { t('overview.general.signals.table.columns.category') }
                    </TableCell>
                    <TableCell>
                      { t('overview.general.signals.table.columns.signal') }
                    </TableCell>
                    <TableCell align="right">
                      { t('common.amount') }
                    </TableCell>
                    <TableCell>
                      { t('overview.general.signals.table.columns.schedule') }
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBodyEl>
                  {
                    !isLoading && signals.map((singleSignal, index) => (
                      <SignalsTableRow
                        key={ index }
                        data={ singleSignal }
                      />
                    ))
                  }
                </TableBodyEl>
              </Table>
            </TableContainer>
          </div>
        )
      } */ }
    </div>
  );
};

export default Liquidity;
