import { ExpenseArrayREGIOS, ExpenseREGIOS, ExpenseKeyREGIOS } from '@/interfaces';

// eslint-disable-next-line import/prefer-default-export
export const expenseArrayToExpense = (key: ExpenseKeyREGIOS, [good, neutral, bad, totalAmount]: ExpenseArrayREGIOS): ExpenseREGIOS => ({
  key,
  ratings: {
    good,
    neutral,
    bad
  },
  totalAmount
});
