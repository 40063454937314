const CASHBACKS = {
  MISSING_CASHBUZZ_TOSPP: 461,
  MISSING_KYC_LIGHT: 462,
  MISSING_KYC_HARD: 463,
  MISSING_TOSPP: 464,
  CASHBACK_ALREADY_REDEEMED: 465,
  CASHBACKS_REQUIREMENTS: 466
};

const EMAIL_VALIDATION = {
  INCORRECT_CODE: 406
};

const ONBOARDING = {
  SUCCESS: 201,
  KYC_REQUIRED: 221,
  NOT_VALIDATED_EMAIL: 241,
  REQUIRE_TOS_ACCEPTANCE: 261
};

const REGISTER = {
  ALREADY_EXIST: 422
};

const SETTINGS = {
  TYPO: 241,
  INVALID_ADDRESS: 261,
  INVALID_BIRTH_DATE: 462
};

const STANDARD = {
  UNAUTHORIZED: 401
};

const USE_CODE = {
  ALREADY_USED: 204,
  INVALID_CODE: 404
};

export { CASHBACKS, EMAIL_VALIDATION, ONBOARDING, REGISTER, SETTINGS, STANDARD, USE_CODE };
