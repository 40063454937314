import React from 'react';

import SpendingsAffinityList from './components/SpendingsAffinityList';
import { Account } from '@/interfaces';
import StatusBar from '@/views/DashboardView/components/StatusBar';

interface RegiosOverviewProps {
  accounts: Account[];
}

const RegiosOverview: React.FC<RegiosOverviewProps> = ({ accounts }) => (
  <section>
    <StatusBar
      accounts={ accounts }
      messages={ [] }
      type="REGIOS"
    />

    { !!accounts.length && <SpendingsAffinityList /> }
  </section>
);


export default RegiosOverview;
