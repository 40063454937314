import { useTheme } from '@material-ui/core/styles';
import type { SkeletonProps } from '@material-ui/lab/Skeleton';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useMemo } from 'react';
import { ChartLabel, RadialChart } from 'react-vis';
import styles from './styles';
import { RatingsAggregatedREGIOS } from '@/interfaces';

const { PI } = Math;
const LABEL_STYLE = {
  transform: 'translate(30,37)',
  textAnchor: 'middle'
};

type RegiosExpensesGaugeType = React.ComponentPropsWithoutRef<'svg'> & RatingsAggregatedREGIOS;

const RegiosExpensesGauge: React.FC<RegiosExpensesGaugeType> = ({ good, neutral, bad }) => {
  const { custom: { colors } } = useTheme();
  const colorRange = useMemo(() => ([colors.success, colors.neutral, colors.error]), [colors]);
  const classes = styles();
  const label = `${good}%`;
  return (
    <RadialChart
      angleRange={ [-PI / 4, 1.75 * PI] }
      colorRange={ colorRange }
      data={ [
        { angle: good, color: 1 },
        { angle: neutral, color: 2 },
        { angle: bad, color: 3 }
      ] }
      height={ 60 }
      innerRadius={ 25 }
      margin={ { left: 1, right: 1, top: 1, bottom: 1 } }
      radius={ 30 }
      width={ 60 }
    >
      <ChartLabel
        className={ classes.gauge }
        style={ LABEL_STYLE }
        text={ label }
        xPercent={ 0 }
        yPercent={ 0 }
      />
    </RadialChart>
  );
};

export const RegiosExpensesGaugeSkeleton: React.FC<Pick<SkeletonProps, 'animation'>> = ({
  animation
}) => (
  <Skeleton
    animation={ animation }
    height={ 60 }
    variant="circle"
    width={ 60 }
  />
);

export default RegiosExpensesGauge;
