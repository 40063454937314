import { Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './components/Card';
import styles from './styles';
import { Metric } from '@/declarations/material-ui';

const Metrics = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = styles();
  const [metrics, setMetrics] = useState<Metric[]>([]);

  useEffect(() => {
    const m = theme.custom
      .metrics
      .filter((metric) => localStorage.getItem(`metric.${metric.id}`) === '')
      .sort((a, b) => a.order_nr - b.order_nr);
    setMetrics(m);
  }, [theme.custom.metrics]);

  const renderView = (): JSX.Element => (
    <>
      <Typography
        className="section-title"
        variant="h4"
      >
        { t('overview.metrics-cards.title') }
      </Typography>
      <div className={ `${classes.section} section` }>
        {
          metrics.map((metric) => (
            <Card
              key={ metric.id }
              metric={ metric }
            />
          ))
        }
      </div>
    </>
  );

  return (
    <>
      { metrics.length > 0 && renderView() }
    </>
  );
};

export default Metrics;
