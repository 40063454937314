import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import type { SkeletonProps } from '@material-ui/lab/Skeleton';
import React from 'react';
import RegiosExpensesGauge, { RegiosExpensesGaugeSkeleton } from './RegiosExpensesGauge';
import styles from './styles';
import ColoredPaper from '@/components/ColoredPaper';
import useIntlNumberFormatter from '@/hooks/useIntlNumberFormatter';
import { ExpenseREGIOS } from '@/interfaces';

type RegiosExpensesCardType = React.ComponentPropsWithoutRef<'div'> & Omit<ExpenseREGIOS, 'key'> & {
  title: string;
};

const RegiosExpensesCard: React.FC<RegiosExpensesCardType> = ({
  title,
  totalAmount,
  ratings
}) => {
  const { formatCurrency } = useIntlNumberFormatter();
  const amountFormatted = formatCurrency(Math.abs(totalAmount));
  const classes = styles();
  return (
    <Box
      mt={ 1 }
    >
      <ColoredPaper
        className={ classes.card }
        component="article"
      >
        <Box
          bgcolor="#fff"
          borderRight="1px solid rgba(0, 0, 0, 0.12)"
          p={ 2 }
        >
          <RegiosExpensesGauge { ...ratings } />
        </Box>
        <Box
          align-items="stretch"
          display="flex"
          flexDirection="column"
          flexGrow={ 1 }
          justifyContent="space-between"
          ml={ 2 }
          p={ 2 }
        >
          <Typography
            className={ classes.cardTitle }
            component="h2"
            variant="h6"
          >
            { title }
          </Typography>
          <Typography align="right">{ amountFormatted }</Typography>
        </Box>
      </ColoredPaper>
    </Box>
  );
};

export const RegiosExpensesCardSkeleton: React.FC<Pick<SkeletonProps, 'animation'>> = ({
  animation
}) => (
  <Box
    mt={ 1 }
  >
    <Card
      component="article"
      style={ { backgroundColor: '#E9F4F3' } }
      variant="outlined"
    >
      <Box
        display="flex"
        p={ 2 }
      >
        <RegiosExpensesGaugeSkeleton animation={ animation } />
        <Box
          align-items="stretch"
          display="flex"
          flexDirection="column"
          flexGrow={ 1 }
          justifyContent="space-between"
          ml={ 2 }
        >
          <Typography
            component="h2"
            variant="h6"
          >
            <Skeleton
              animation={ animation }
              width={ 100 }
            />
          </Typography>
          <Box alignSelf="flex-end">
            <Skeleton
              animation={ animation }
              height={ 24 }
              width={ 60 }
            />
          </Box>
        </Box>
      </Box>
    </Card>
  </Box>
);

export default RegiosExpensesCard;
