import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '@/reducers';

type UseCategoriesResult = {
  getLabel: (category: string) => string;
}
export default function useCategories(): UseCategoriesResult {
  const { i18n } = useTranslation();
  const labelKey = useMemo(
    () => `label_${i18n.language}` as 'label_de' | 'label_en',
    [i18n.language]
  );
  const labels = useSelector((state: AppState) => state.cashbuzz.qclasses);
  const getLabel = useCallback((cat: string) => {
    const label = labels.find((l) => l.label === cat);
    return label ? label[labelKey] : `[unknown category: ${cat}]`;
  }, [labels, labelKey]);
  return {
    getLabel
  };
}
