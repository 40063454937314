import {
  TableCell,
  TableRow,
  IconButton,
  Collapse
} from '@material-ui/core';
import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import SVG from 'react-inlinesvg';

import styles from './styles';
import { ICONS } from '@/assets';
import { Nullable } from '@/types';


export interface IRow {
  id: string;
  detail?: boolean;
  values: IColumn[];
}

interface IColumn {
  image?: boolean;
  className?: string;
  value: Nullable<string>;
}

interface Props {
  row: IRow;
}

const Row = (props: PropsWithChildren<Props>): JSX.Element => {
  const { row, children } = props;
  const classes = styles();
  const [open, setOpen] = useState(false);

  const renderToggleCell = (): JSX.Element | null => {
    if (row.detail) {
      return (
        <TableCell>
          <IconButton
            aria-label="expand row"
            onClick={ (): void => {
              if (!open) {
                // sendItemEvent(partnerId, 'general-income', 'item_view');
              }
              setOpen(!open);
            } }
            size="small"
          >
            <SVG src={ ICONS.CHEVRON_DOWN } />
          </IconButton>
        </TableCell>
      );
    } return null;
  };

  const renderCell = (c: IColumn, i: number): JSX.Element => {
    if (c.image && c.value) {
      return (
        <TableCell key={ `${row.id}-${i}` }>
          <img
            src={ c.value }
            style={ { height: 30 } }
          />
        </TableCell>
      );
    }
    return <TableCell key={ i }>{ c.value }</TableCell>;
  };

  const renderChildren = (): JSX.Element | null => {
    if (row.detail) {
      return (
        <TableRow
          className={ classNames({
            [classes.expandableRow]: true,
            expanded: open
          }) }
        >
          <TableCell
            colSpan={ row.values.length + 1 }
          >
            <Collapse
              in={ open }
              timeout="auto"
              unmountOnExit
            >
              { children }
            </Collapse>
          </TableCell>
        </TableRow>
      );
    } return null;
  };

  return (
    <>
      <TableRow
        className={ classNames({
          [classes.row]: row.detail,
          expanded: open
        }) }
      >
        { renderToggleCell() }
        { row.values.map((c, i) => renderCell(c, i)) }
      </TableRow>
      { renderChildren() }
    </>
  );
};

export default Row;
