import { Switch, TableCell, TableRow, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { Metric } from '@/declarations/material-ui';
import i18n from '@/i18n';
import APIService from '@/services/APIService';
import { getCurrentLanguage, to6391Format } from '@/utils/languages';

interface Props {
  metric: Metric;
  startDate?: Date;
  endDate?: Date;
  quantization: string;
}

const Row = (p: Props): JSX.Element => {
  const theme = useTheme();
  const colors = theme.custom.colors as any;
  const m = p.metric;
  const isEnglish = getCurrentLanguage() === 'en';
  const storageKey = `metric.${m.id}`;
  const title = isEnglish ? m.title_en : m.title_de;
  const text = isEnglish ? m.text_en : m.text_de;
  const unit = isEnglish ? m.unit_en : m.unit_de;
  const iconColor = colors[m.icon_color];
  const item = localStorage.getItem(storageKey);

  const [insight, setInsight] = useState<number>(0);
  const [toggle, setToggle] = useState(item !== null);
  const onToggle = (): void => {
    const v = !toggle;
    if (v) localStorage.setItem(storageKey, '');
    else localStorage.removeItem(storageKey);
    setToggle(v);
  };
  const formatValue = (v: number): string => {
    const fractionDigits = m.value_format === 'amount' ? 2 : 0;
    v = m.value_format === 'percentage' ? Math.round(v * 100) : v;
    return Intl.NumberFormat(to6391Format(i18n.language), {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    }).format(v);
  };
  const getValueColor = (v: number): string => {
    let color = v > 0 ? theme.custom.colors.amountPositive : theme.custom.colors.amountNegative;
    color = v === 0 ? theme.custom.colors.neutral : color;
    color = m.value_color === 'amount' ? color : colors[m.value_color];
    return color;
  };

  const render = (): JSX.Element => {
    if (m.display_type === 'value_card') {
      const paragraphs = text.split('{value}');
      return (
        <>
          <Typography>{ paragraphs[0] }</Typography>
          <Typography>
            <span style={ { color: getValueColor(insight), fontSize: theme.custom.sizes.large } }>{ formatValue(insight) }</span>
            { ' ' }
            <span style={ { fontSize: theme.custom.sizes.small } }>{ unit }</span>
          </Typography>
          <Typography>{ paragraphs[1] }</Typography>
        </>
      );
    }
    const t = isEnglish
      ? 'The result is a data series which is not shown here. Set the toggle at the left to watch the data series in the dashboard'
      : 'Das Ergebnis ist eine Datenreihe, die hier nicht angezeigt wird. Setzen Sie den Schalter links, um die Datenreihe in der Übersicht anzuzeigen';
    return (
      <Typography>{ t }</Typography>
    );
  };

  useEffect(() => {
    APIService.insights.get(m.insight, p.startDate, p.endDate, p.quantization)
      .then((response) => {
        const { value } = response.data[0];
        const multiplier = value !== 0 && m.multiply_values_by_m1 ? -1 : 1;
        setInsight(value * multiplier);
      });
  }, [m.insight, m.multiply_values_by_m1, p.startDate, p.endDate, p.quantization]);

  return (
    <TableRow>
      <TableCell style={ { color: iconColor } }>
        <SVG
          src={ m.icon }
        />
      </TableCell>
      <TableCell>
        { title }
      </TableCell>
      <TableCell>
        <Switch
          checked={ toggle }
          color="primary"
          onChange={ (): void => { onToggle(); } }
        />
      </TableCell>
      <TableCell>
        { render() }
      </TableCell>
    </TableRow>
  );
};


export default Row;
