import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },

  title: {
    fontWeight: 600,
    animation: '$appear 300ms'
  },

  list: {
    padding: 0,
    marginTop: theme.spacing(1),
    overflow: 'hidden',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1)
    }
  },

  separatorTitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },

  '@keyframes appear': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }
}), { name: 'transaction-group-item' });
