import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  list: {
    listStyleType: 'none',
    padding: 0,
    '& a': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  card: {
    display: 'flex'
  },
  cardTitle: {
    fontSize: theme.typography.body1.fontSize
  },
  gauge: {
    '& > text': {
      fill: theme.custom.colors.success,
      fontSize: theme.typography.body1.fontSize,
      textAlign: 'center',
      lineHeight: 1
    }
  }
}), { name: 'expenses-affinity' });
