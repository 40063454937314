import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import ListItem, { SingleTransaction } from '../Item';
import ColoredPaper from '@/components/ColoredPaper';
import { Organization, TransactionREGIOS } from '@/interfaces';

type Props = {
  label: string;
  firstOlder30days?: boolean;
  transactions: ReadonlyArray<SingleTransaction>;
  onSelectItem?: (transaction: TransactionREGIOS, org?: Organization) => void | Promise<void>;
};

const SameDayTransactions: FC<Props> = ({ label, transactions, firstOlder30days = false, onSelectItem = (): void => {} }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <>
      { firstOlder30days
        ? (
          <Typography
            className={ classes.separatorTitle }
            variant="h6"
          >
            { t('expenses.regios.older-30-days') }
          </Typography>
        )
        : null }
      <ColoredPaper
        className={ classes.root }
      >
        <Typography className={ classes.title }>
          { label }
        </Typography>
        <List className={ classes.list }>
          { transactions.map((singleTransaction) => (
            <ListItem
              key={ singleTransaction.transaction.id }
              onSelect={ onSelectItem }
              organization={ singleTransaction.organization }
              transaction={ singleTransaction.transaction }
            />
          )) }
        </List>
      </ColoredPaper>
    </>
  );
};

export default SameDayTransactions;
