import {
  ListItem,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useCallback } from 'react';

import styles from './styles';
import CircleIcon from '../CircleIcon';
import { ICONS } from '@/assets';
import useCategories from '@/hooks/useCategoryLabel';
import useIntlNumberFormatter from '@/hooks/useIntlNumberFormatter';
import { Organization, TransactionREGIOS } from '@/interfaces';
import { getText } from '@/utils/data';

export interface SingleTransaction {
  transaction: TransactionREGIOS;
  organization?: Organization;
}

interface Props extends SingleTransaction {
  onSelect?: (transaction: TransactionREGIOS, org?: Organization) => void | Promise<void>;
}

const getOrgName = (organization?: Organization, counterpartName?: string): string => (
  organization?.generic && counterpartName
    ? counterpartName
    : getText(organization ? organization.name : undefined));

const TransactionListItem = ({ transaction, organization, onSelect = (): void => {} }: Props): JSX.Element => {
  const classes = styles();
  const { getLabel } = useCategories();
  const { formatCurrency } = useIntlNumberFormatter();

  const onClickHandler = useCallback(() => onSelect(transaction, organization), [onSelect, organization, transaction]);
  const { amount, group_gstd, counterpartName } = transaction;
  const amountFormatted = formatCurrency(Math.abs(amount));
  const orgName = getOrgName(organization, counterpartName);

  return (
    <ListItem
      button
      className={ classes.root }
      onClick={ onClickHandler }
    >
      <div className={ classes.iconSection }>
        <CircleIcon
          icon={ organization?.logo_sqrd || ICONS.BOOK_OPEN_PAGE_VARIANT_OUTLINE }
          rating={ transaction.regiox_rating }
        />
      </div>
      <Box
        className={ classes.contentSection }
      >
        <Typography
          className={ classes.organizationLabel }
          component="h4"
        >
          { orgName }
        </Typography>
        <Box
          className={ classes.contentBottom }
        >
          <Typography
            className={ classes.categoryLabel }
            component="h5"
          >
            { getLabel(group_gstd || '') }
          </Typography>
          <Typography className={ classes.amountSection }>{ amountFormatted }</Typography>
        </Box>
      </Box>

    </ListItem>
  );
};

export default TransactionListItem;
