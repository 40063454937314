import { useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import styles from './styles';
import { RatingREGIOS } from '@/interfaces';

interface Props {
  icon: string;
  height?: number;
  rating?: RatingREGIOS;
  width?: number;
}

const CircleIcon = ({
  icon,
  height = 24,
  rating = 'neutral',
  width = 24
}: Props): JSX.Element => {
  const { custom: { colors } } = useTheme();
  const color = useMemo(() => {
    switch (rating) {
      case 'bad':
        return colors.error;
      case 'neutral':
        return colors.neutral;
      case 'good':
      default:
        return colors.success;
    }
  }, [colors, rating]);
  const classes = styles({ color, height, width });
  return (
    <div className={ classes.root }>
      {
        icon.startsWith('<svg')
          ? <SVG src={ icon } />
          : (
            <img
              alt="logo"
              src={ icon }
            />
          )
      }
    </div>
  );
};

export default CircleIcon;
