import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({

  hint: {
    background: 'black',
    opacity: '0.9',
    borderRadius: '8px',
    padding: '8px'
  }
}), { name: 'area-chart' });
