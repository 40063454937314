import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@material-ui/core';
import { addDays, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from './components/Row';
import { IDateRangePickerConfig } from '@/components/DateRangePicker';
import PageHeader from '@/components/PageHeader';
import CustomTableBody from '@/components/Table/Body';
import { Account } from '@/interfaces';


export interface IMetricsConfiguration {
  startDate?: Date;
  endDate?: Date;
  quantization?: string;
}

interface Props {
  accounts: Account[];
}

const Metrics = (p: Props): JSX.Element => {
  const storageKey = 'metrics';
  const theme = useTheme();
  const { t } = useTranslation();
  const { metrics } = theme.custom;
  const [dateRangePicker, setDateRangePicker] = useState<IDateRangePickerConfig | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [quantization, setQuantization] = useState<string>('');

  useEffect(() => {
    if (p.accounts.length) {
      const serializedConfig = localStorage.getItem(storageKey);
      const config: IMetricsConfiguration = serializedConfig ? JSON.parse(serializedConfig) : {};
      const defaultStartDate = config.startDate ? new Date(config.startDate) : addDays(subMonths(new Date(), 3), 1);
      const defaultEndDate = config.endDate ? new Date(config.endDate) : new Date(p.accounts[0].last_synced);
      const defaultQuantization = config.quantization ? config.quantization : 'month';
      const storeConfig = (): void => localStorage.setItem(storageKey, JSON.stringify(config));
      const setAndStoreStartDate = (d: Date): void => {
        config.startDate = d;
        setStartDate(d);
        storeConfig();
      };
      const setAndStoreEndDate = (d: Date): void => {
        const lastSynced = new Date(p.accounts[0].last_synced);
        config.endDate = d.getTime() > lastSynced.getTime() ? lastSynced : d;
        setEndDate(config.endDate);
        storeConfig();
      };
      const setAndStoreQuantization = (q: string): void => {
        config.quantization = q;
        setQuantization(q);
        storeConfig();
      };
      setDateRangePicker({
        start: defaultStartDate,
        end: defaultEndDate,
        onStartChange: (start: Date) => setAndStoreStartDate(start),
        onEndChange: (end: Date) => setAndStoreEndDate(end)
      });
      setAndStoreStartDate(defaultStartDate);
      setAndStoreEndDate(defaultEndDate);
      setAndStoreQuantization(defaultQuantization);
    }
  }, [p.accounts]);

  return (
    <div className=" dashboard-view-element-root">
      <div>
        <PageHeader
          dateRangePicker={ dateRangePicker }
          title={ t('metrics.title') }
        />
      </div>
      <div>
        <FormControl>
          <FormLabel id="quantization-radio-buttons">{ t('metrics.quantization.title') }</FormLabel>
          <RadioGroup
            aria-labelledby="quantization-radio-buttons"
            name="radio-buttons-group"
            onChange={ (e, v): void => {
              const serializedConfig = localStorage.getItem(storageKey);
              if (serializedConfig) {
                const config: IMetricsConfiguration = JSON.parse(serializedConfig);
                config.quantization = v;
                localStorage.setItem(storageKey, JSON.stringify(config));
                setQuantization(v);
              }
            } }
            row
            value={ quantization }
          >
            <FormControlLabel
              control={ <Radio /> }
              label={ t('metrics.quantization.week') }
              value="week"
            />
            <FormControlLabel
              control={ <Radio /> }
              label={ t('metrics.quantization.month') }
              value="month"
            />
            <FormControlLabel
              control={ <Radio /> }
              label={ t('metrics.quantization.quarter') }
              value="quarter"
            />
            <FormControlLabel
              control={ <Radio /> }
              label={ t('metrics.quantization.year') }
              value="year"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        <TableContainer
          component={ Paper }
          style={ { overflowY: 'hidden' } }
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  { t('common.logo') }
                </TableCell>
                <TableCell>
                  { t('metrics.table.kpi') }
                </TableCell>
                <TableCell>
                  { t('metrics.table.card') }
                </TableCell>
                <TableCell>
                  { t('metrics.table.performance') }
                </TableCell>
              </TableRow>
            </TableHead>
            <CustomTableBody
              customEmptyText={ t('tables.no_data') }
              empty={ !metrics.length }
              hasPagination={ false }
              loading={ false }
            >
              {
                metrics.sort((a, b) => a.order_nr - b.order_nr).map((m) => (
                  <Row
                    key={ m.id }
                    endDate={ endDate }
                    metric={ m }
                    quantization={ quantization }
                    startDate={ startDate }
                  />
                ))
              }
            </CustomTableBody>
          </Table>
          { /* {
            pagination && pagination.pageCount > 1
            && (
              <PaginationEl
                color="primary"
                count={ pagination?.pageCount || 0 }
                onChange={ (event, page): void => { setActivePage(page); } }
              />
            )
          } */ }
        </TableContainer>
      </div>
    </div>
  );
};

export default Metrics;
