import React from 'react';
import { HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis } from 'react-vis';


interface IPoint {
  x: number;
  y: number;
}

interface ISeries {
  color?: string;
  data: IPoint[];
}

interface IAxisConfig {
  format?: (v: number) => string;
}

export interface ILineChartConfig {
  hint?: string;
  series: ISeries;
  xAxis?: IAxisConfig;
  yAxis?: IAxisConfig;
}

interface Props {
  width?: number;
  config: ILineChartConfig;
}

const LineChart = (p: Props): JSX.Element => {
  const c: ILineChartConfig = p.config
    ? p.config
    : { series: { data: [] } };
  const xValues: number[] = c.series.data.map((d) => d.x);
  const max: number = c.series.data.length
    ? c.series.data.map((d) => Math.abs(d.y)).reduce((a, b) => (a > b ? a : b))
    : 100;
  return (
    <XYPlot
      // className={ classes.root }
      height={ 200 }
      margin={ { left: 60, bottom: 60 } }
      // onMouseLeave={ (): void => { setHintValue(undefined); } }
      stackBy="y"
      width={ 252 }
      yDomain={ [0, max + (max * 0.1)] }
    >
      <HorizontalGridLines />
      <XAxis
        // className={ classes.tickLabel }
        tickFormat={ c.xAxis?.format }
        tickLabelAngle={ -90 }
        tickTotal={ 40 }
        tickValues={ xValues }
      />
      <YAxis
        tickFormat={ c.yAxis?.format }
      // tickValues={ ticks }
      />

      <LineMarkSeries

        color={ c.series.color }
        data={ c.series.data }
        // onValueMouseOver={ (value): void => {
        //   setHintValue({ x: value.x, y: value.y });
        // } }
      />
    </XYPlot>
  );
};

export default LineChart;
