import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .search-field': {
      marginBottom: '24px',
      maxWidth: '410px',

      '& svg': {
        width: '24px'
      }
    },

    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  wrapper: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    placeItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    opacity: 0.7,
    zIndex: 1
  },
  bankCardSection: {
    maxWidth: 1272,
    margin: '40px auto 60px',
    display: 'flex',
    flexWrap: 'wrap',

    width: '100%'
  },
  bankCard: {
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    height: theme.spacing(8),
    cursor: 'pointer',
    border: '2px solid',
    borderColor: theme.custom.colors.primary,
    boxShadow: 'none',
    justifyContent: 'start',
    textTransform: 'none',

    '& .MuiButton-label': {
      '& h6': {
        fontSize: '1rem'
      }
    },

    '&:hover': {
      backgroundColor: '#CAE1FF'
    },

    '& svg': {
      maxHeight: theme.spacing(5),
      width: theme.spacing(5)
    },

    '& img': {
      maxHeight: theme.spacing(5),
      width: theme.spacing(5)
    },

    '&.mocked': {
      '& svg': {
        fill: '#757575'
      }
    }
  },
  bankCardLabel: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(14),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}), { name: 'bank-list-table' });
