import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Colors } from '@/declarations/material-ui';

const getRootStyle = (colors?: Colors): ClassNameMap => {
  if (colors?.cardBackground === undefined) {
    return {};
  }
  if (colors.cardBackgroundOpacity === undefined || colors.cardBackgroundOpacity >= 1) {
    return {
      backgroundColor: colors.cardBackground
    };
  }
  return {
    backgroundColor: fade(colors.cardBackground, colors.cardBackgroundOpacity || 0.6),
    backdropFilter: 'blur(3px)'
  };
};

export default makeStyles(
  (theme) => ({
    root: getRootStyle(theme.custom?.colors)
  }),
  { name: 'colored-paper' }
);
