import type { PaperProps } from '@material-ui/core/Paper';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import styles from './styles';

const ColoredPaper: React.FC<PaperProps> = ({ className, ...props }) => {
  const classes = styles();
  return (
    <Paper
      className={ className ? `${className} ${classes.root}` : classes.root }
      { ...props }
    />
  );
};

export default ColoredPaper;
